import { isDefined } from "@clipboard-health/util-ts";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Stack } from "@mui/material";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { useDocumentDetailsContext } from "../../useDocumentDetails";
import { useDocumentDetailsFooterContext } from "./FooterContext";
import { getCompositeChildrenProperties } from "./FooterContext/utils/getCompositeChildrenProperties";
import {
  getDocumentDetailsFooterButtonProperties,
  type GetDocumentDetailsFooterButtonPropertiesResponse,
} from "./FooterContext/utils/getDocumentDetailsFooterButtonProperties";
import { DocumentDetailsFooterType } from "./types";

interface DocumentDetailsFooterButtonsProps {
  setDrawerIsVisible: (drawerIsVisible: boolean) => void;
}

function renderUploadDocumentOrReplacementButton(
  props: GetDocumentDetailsFooterButtonPropertiesResponse
) {
  const { buttonText, buttonVariant, onClick } = props;
  return (
    <Button key={buttonText} fullWidth variant={buttonVariant} onClick={onClick}>
      {buttonText}
    </Button>
  );
}

export function DocumentDetailsFooterButton(props: DocumentDetailsFooterButtonsProps) {
  const { setDrawerIsVisible } = props;
  const { selectedRequirement, requirementType, documentsDropOffFlowId } =
    useDocumentDetailsContext();
  const { showErrorToast } = useToast();

  const {
    footerType,
    finishAndUpload,
    startStripeFlow,
    deleteDocument,
    startHelloSignEmbeddedFlow,
  } = useDocumentDetailsFooterContext();

  const isCompositeRequirement =
    selectedRequirement?.requirementWorkflowType === RequirementWorkflowType.COMPOSITE;

  if (
    footerType === DocumentDetailsFooterType.UPLOAD_DOCUMENT ||
    footerType === DocumentDetailsFooterType.UPLOAD_REPLACEMENT
  ) {
    if (isCompositeRequirement) {
      const compositeChildrenProperties = getCompositeChildrenProperties({ selectedRequirement });
      const { compositeChildOne, compositeChildTwo } = compositeChildrenProperties ?? {};

      if (
        !isDefined(compositeChildrenProperties) ||
        !isDefined(compositeChildOne) ||
        !isDefined(compositeChildTwo)
      ) {
        showErrorToast("Something went wrong while loading this document. Please try again later");
        logEvent(APP_V2_APP_EVENTS.INVALID_COMPOSITE_CONFIGURATION, {
          requirementId: selectedRequirement?._id,
        });
        return null;
      }

      const buttonProps = [compositeChildOne, compositeChildTwo].map(
        ({ requirementWorkflowType, ctaText }) =>
          getDocumentDetailsFooterButtonProperties({
            footerType,
            requirementType,
            requirementWorkflowType,
            setDrawerIsVisible,
            startHelloSignEmbeddedFlow,
            buttonCtaText: ctaText,
            selectedRequirement,
            documentsDropOffFlowId,
          })
      );

      return (
        <Stack direction="column" spacing={1}>
          {buttonProps.map((buttonProps) => renderUploadDocumentOrReplacementButton(buttonProps))}
        </Stack>
      );
    }

    const buttonProps = getDocumentDetailsFooterButtonProperties({
      footerType,
      requirementType,
      requirementWorkflowType: selectedRequirement?.requirementWorkflowType,
      setDrawerIsVisible,
      startHelloSignEmbeddedFlow,
      selectedRequirement,
      documentsDropOffFlowId,
    });
    return renderUploadDocumentOrReplacementButton(buttonProps);
  }

  if (footerType === DocumentDetailsFooterType.ADD_MORE_IMAGES_AND_FINISH_AND_UPLOAD) {
    return (
      <Stack direction="column" spacing={1}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setDrawerIsVisible(true);
          }}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            <CameraAltIcon />
            <span>Add more images</span>
          </Stack>
        </Button>

        <Button fullWidth variant="contained" onClick={finishAndUpload}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <CloudUploadIcon />
            <span>Finish and Upload</span>
          </Stack>
        </Button>
      </Stack>
    );
  }

  if (footerType === DocumentDetailsFooterType.FINISH_AND_UPLOAD) {
    return (
      <Button fullWidth variant="contained" onClick={finishAndUpload}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <CloudUploadIcon />
          <span>Finish and Upload</span>
        </Stack>
      </Button>
    );
  }

  if (footerType === DocumentDetailsFooterType.VERIFY_ID_WITH_STRIPE) {
    return (
      <Button fullWidth variant="contained" onClick={startStripeFlow}>
        Verify ID With Stripe
      </Button>
    );
  }

  if (footerType === DocumentDetailsFooterType.DELETE_DOCUMENT) {
    return (
      <Button
        fullWidth
        variant="outlined"
        color="error"
        sx={{ color: "error.main" }}
        onClick={deleteDocument}
      >
        Delete Uploaded Document
      </Button>
    );
  }

  // We should never get here because we have exhaustive if statements
  return null;
}
